import React from 'react';
import styled from 'styled-components'
import {InfoItem} from "../../../../types/infoItem";
import LinkButtonDefault from "../../../atoms/share/button/LinkButtonDefault";
import ShowInfoItem from "../../../organisms/share/ShowInfoItem";

interface ShowProps {
    infoItem: InfoItem;
    editable: boolean;
}

export const Show = ({infoItem, editable = false}: ShowProps) => {
    return (
        <ShowArea>
            {editable && (
                <ButtonWrapper>
                    <LinkButtonDefault path={`/management/info_items/${infoItem.id}/edit`} text='編集'/>
                </ButtonWrapper>
            )}
            <ShowInfoItem infoItem={infoItem} />
        </ShowArea>
    )
}

const ShowArea = styled.div``;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default Show
