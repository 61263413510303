import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import LinkButton from '../../../atoms/share/LinkButton'
import { fetchEmploymentStatuses, deleteEmploymentStatus } from '../../../../api/employment_status'
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import TableBodyGrid2 from '../../../atoms/share/TableBodyGrid2';
import LinkButtonDefault from '../../../atoms/share/button/LinkButtonDefault';

export const EmploymentStatusList = () => {
  const [employment_statuses, setEmploymentStatuses] = useState([]);

  useEffect(() => {
    fetchEmploymentStatuses()
      .then((res) => {
        setEmploymentStatuses(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }, []);

  const heads = ['雇用形態名', '']

  const handleDelete = (id: number) => {
    const confirmed = window.confirm('雇用形態を削除します。よろしいですか？');

    if (confirmed) {
      deleteEmploymentStatus(id)
        .then((res) => {
          setEmploymentStatuses(
            employment_statuses.filter(item =>
              item.id !== res.data.id
            )
          )
        })
        .catch(() => {
          window.alert('通信に失敗しました')
        });
    }
  }

  return (
    <EmploymentStatusListArea>
      <ButtonBox>
        <LinkButton text='新規登録' path='/management/employment_statuses/new' />
      </ButtonBox>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {employment_statuses.map((employment_status, index) => (
            <Row key={index}>
              <TableBody>{employment_status.name}</TableBody>
              <TableBodyGrid2>
                <TableButtonArea>
                  <LinkButtonDefault path={`/management/employment_statuses/${employment_status.id}/edit`} text='編集' />
                </TableButtonArea>
                <TableButtonArea>
                  <ButtonRed text='削除' onClick={() => handleDelete(employment_status.id)} />
                </TableButtonArea>
              </TableBodyGrid2>
            </Row>
          ))}
        </Tbody>
      </Table>
    </EmploymentStatusListArea>
  )
}

const EmploymentStatusListArea = styled.div`
  margin: 0 0 100px 0;
`
const Table = styled.table`
  border-collapse: collapse;
  margin: 0;
  width: 80%;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr`
`
const ButtonBox = styled.div`
  width: 80%;
  margin: 0;
  text-align: right;
  margin-bottom: 15px;
  font-size: 16px;
`
const TableButtonArea = styled.span`
  display: block;
  text-align: center;
`

export default EmploymentStatusList
