import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import TextForm from '../../../atoms/TextForm'
import SubmitButton from '../../../atoms/SubmitButton'
import TextAreaForm from "../../../atoms/share/TextAreaForm";
import {InfoItemRequestParams} from "../../../../api/info_item";
import Select from "react-select";
import {days, months} from "../../../../modules/date";
import {InfoItem} from "../../../../types/infoItem";
import {AxiosResponse} from 'axios';
import formatDateTime from '../../../../utils/formatDateTime';

const years = () => {
    const now = new Date()
    const years = []
    const y = now.getFullYear()
    for (let i = 0; i <= 3; i++) {
        years.push({ value: y + i, label: y + i })
    }
    return years
}

export const hours = () => {
    const arr = []
    for (let i = 0; i < 24; i++) {
        arr.push({ value: i, label: i })
    }
    return arr
}

type InfoItemFormProps = {
    infoItem?: InfoItem;
    submitHandler: (params: InfoItemRequestParams) => Promise<AxiosResponse<any>>
}

export const InfoItemForm = ({infoItem, submitHandler}: InfoItemFormProps) => {
    const [title, setTitle] = useState(infoItem ? infoItem.title : "")
    const [detailText, setDetailText] = useState(infoItem ? infoItem.detail_text : "")
    const [loading, setLoading] = useState(false)
    const [completeSignup, setCompleteSignup] = useState(false)
    const [selectedStartYear, setSelectedStartYear] = useState(null);
    const [selectedStartMonth, setSelectedStartMonth] = useState(null);
    const [selectedStartDay, setSelectedStartDay] = useState(null);
    const [selectedStartHour, setSelectedStartHour] = useState(null);
    const [selectedEndYear, setSelectedEndYear] = useState(null);
    const [selectedEndMonth, setSelectedEndMonth] = useState(null);
    const [selectedEndDay, setSelectedEndDay] = useState(null);
    const [selectedEndHour, setSelectedEndHour] = useState(null);

    useEffect(() => {
        if (!infoItem) return

        const startAt = new Date(infoItem.start_at);
        setSelectedStartYear(buildOptionFromValue(startAt.getFullYear()))
        setSelectedStartMonth(buildOptionFromValue(startAt.getMonth() + 1))
        setSelectedStartDay(buildOptionFromValue(startAt.getDate()))
        setSelectedStartHour(buildOptionFromValue(startAt.getHours()))

        const endAt = new Date(infoItem.end_at)
        setSelectedEndYear(buildOptionFromValue(endAt.getFullYear()))
        setSelectedEndMonth(buildOptionFromValue(endAt.getMonth() + 1))
        setSelectedEndDay(buildOptionFromValue(endAt.getDate()))
        setSelectedEndHour(buildOptionFromValue(endAt.getHours()))
    }, [])

    const buildOptionFromValue = (num: number): { value: number, label: number } => {
        return {label: num, value: num}
    }

    const handleSubmit = async () => {
        setLoading(true)
        const start_at = formatDateTime(selectedStartYear?.value, selectedStartMonth?.value, selectedStartDay?.value, selectedStartHour?.value, 0, 0);
        const end_at = formatDateTime(selectedEndYear?.value, selectedEndMonth?.value, selectedEndDay?.value, selectedEndHour?.value, 0, 0);
        const params = {
            title, detail_text: detailText, start_at, end_at
        }
        if (!start_at || !end_at) {
            alert('日時が不正です')
            setLoading(false)
            return
        }

        try {
            await submitHandler(params)
            if (!infoItem) {
                // 新規作成の場合はフォームをクリアする
                initialForm()
            }
            setCompleteSignup(true)
        } catch (e) {
            console.error('通信に失敗しました')
            window.alert('通信に失敗しました')
        } finally {
            setLoading(false)
        }
    }

    const initialForm = () => {
        setTitle("")
        setDetailText("")
        setSelectedStartYear(null)
        setSelectedStartMonth(null)
        setSelectedStartDay(null)
        setSelectedStartHour(null)
        setSelectedEndYear(null)
        setSelectedEndMonth(null)
        setSelectedEndDay(null)
        setSelectedEndHour(null)
    }

    const noticeComplete = () => {
        if (completeSignup) {
            return (
                <p>{`${infoItem ? '更新' : '登録'}が完了しました`}</p>
            )
        }
    }

    return (
        <Form>
            <Notification>
                {noticeComplete()}
            </Notification>
            <Item>
                <Label>タイトル</Label>
                <TextForm type="text" placeholder="お知らせのタイトル" value={title}
                          onChange={(e) => setTitle(e.target.value)}/>
            </Item>
            <Item>
                <Label>本文</Label>
                <TextAreaForm placeholder="本文" value={detailText} onChange={(e) => setDetailText(e.target.value)}/>
            </Item>
            <Item>
                <Label>表示開始日時</Label>
                <SelectSection>
                    <SelectBox><Select value={selectedStartYear} onChange={setSelectedStartYear}
                                       options={years()}/>年</SelectBox>
                    <SelectBox><Select value={selectedStartMonth} onChange={setSelectedStartMonth} options={months}/>月</SelectBox>
                    <SelectBox><Select value={selectedStartDay} onChange={setSelectedStartDay}
                                       options={days}/>日</SelectBox>
                    <SelectBox><Select value={selectedStartHour} onChange={setSelectedStartHour}
                                       options={hours()}/>時</SelectBox>
                </SelectSection>
            </Item>
            <Item>
                <Label>表示終了日時</Label>
                <SelectSection>
                    <SelectBox><Select value={selectedEndYear} onChange={setSelectedEndYear}
                                       options={years()}/>年</SelectBox>
                    <SelectBox><Select value={selectedEndMonth} onChange={setSelectedEndMonth}
                                       options={months}/>月</SelectBox>
                    <SelectBox><Select value={selectedEndDay} onChange={setSelectedEndDay}
                                       options={days}/>日</SelectBox>
                    <SelectBox><Select value={selectedEndHour} onChange={setSelectedEndHour}
                                       options={hours()}/>時</SelectBox>
                </SelectSection>
            </Item>
            <RegistrationButton>
                <SubmitButton
                    text={`${infoItem ? '更新する' : '新規登録する'}`}
                    onClick={handleSubmit}
                    disabled={loading || !title || !detailText}
                />
            </RegistrationButton>
        </Form>
    )
}

const Form = styled.form`
    margin: 76px 100px 0;
    font-size: 16px;
`
const Notification = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #06d6a0;
`
const Item = styled.div`
    align-items: center;
    display: flex;
    line-height: 43px;
    list-style: none;
    margin-bottom: 24px;
`
const Label = styled.label`
    color: #707071;
    text-align: center;
    width: 30%;
`
const SelectBox = styled.div`
    display: flex;
    margin: 0 5px;
    align-items: center;
`
const RegistrationButton = styled.div`
    width: 100%;
    text-align: center;
    margin: 75px 0 0 0;
`

const SelectSection = styled.div`
    display: flex;
    width: 100%;
`

export default InfoItemForm
