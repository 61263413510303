export default (year: number, month: number, day: number, hour: number, minute: number, second: number): string => {
    // 入力がすべて数値であることを確認
    if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hour) || isNaN(minute)) {
        return null; // 無効な入力
    }

    // 月と日が有効な範囲にあるか確認
    if (month < 1 || month > 12 || day < 1 || day > 31) {
        return null;
    }

    // 時間と分の範囲チェック
    if (hour < 0 || hour > 23 || minute < 0 || minute > 59 || second < 0 || second > 59) {
        return null;
    }

    // 日付の妥当性を確認（例えば 2月30日などが無効）
    const dateObj = new Date(year, month - 1, day, hour, minute, 0);
    if (dateObj.getFullYear() !== year || dateObj.getMonth() + 1 !== month || dateObj.getDate() !== day) {
        return null;
    }

    return `${padStartAlternative(year.toString(), 4, '0')}-${padStartAlternative(month.toString(), 2, '0')}-${padStartAlternative(day.toString(), 2, '0')} `
    + `${padStartAlternative(hour.toString(), 2, '0')}:${padStartAlternative(minute.toString(), 2, '0')}:${padStartAlternative(second.toString(), 2, '0')}`;
}

/**
 * padStart がコンパイルできなかったので代理メソッドを作成
 * @param {string} str
 * @param {number} targetLength
 * @param {string} padChar Default = 0
 */
const padStartAlternative = (str: string, targetLength: number, padChar='0') => {
    return (padChar.repeat(targetLength) + str).slice(-targetLength);
}