import React from 'react';
import styled from 'styled-components'
import EditForm from '../../../organisms/management/info_items/EditForm'
import {InfoItem} from "../../../../types/infoItem";

interface EditProps {
  infoItem: InfoItem;
}

export const Edit = ({ infoItem }: EditProps) => {
  return (
    <EditArea>
      <EditForm infoItem={infoItem} />
    </EditArea>
  )
}

const EditArea = styled.div``;

export default Edit
