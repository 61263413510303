import client from './client'

export const fetchInfoItems = () => {
  return client.get('/api/v1/info_items')
}

export type InfoItemRequestParams = {
  title: string;
  detail_text: string;
  start_at: string;
  end_at: string;
}

export const createInfoItem = (params: InfoItemRequestParams) => {
  return client.post('/api/v1/info_items', params)
}

export const updateInfoItem= (id: number, params: InfoItemRequestParams) => {
  return client.put(`/api/v1/info_items/${id}`, params)
}

export const deleteInfoItem = (id: number) => {
  return client.delete(`/api/v1/info_items/${id}`)
}
