import React from 'react';
import {InfoItem} from "../../../../types/infoItem";
import ShowInfoItem from "../../../organisms/share/ShowInfoItem";
import styled from "styled-components";

interface ShowProps {
    infoItem: InfoItem;
}

export const Show = ({infoItem}: ShowProps) => {
    return (
        <ShowArea>
            <ShowInfoItem infoItem={infoItem} />
        </ShowArea>
    )
}

const ShowArea = styled.div``;

export default Show
