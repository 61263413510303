import React from 'react'
import styled from 'styled-components'
import {InfoItem} from "../../../types/infoItem";
import BannerLink from "../../atoms/share/BannerLink";

type InfoItemBannersProps = {
    infoItems: InfoItem[];
    pathPrefix: string;
}

export const InfoItemBanners = ({infoItems, pathPrefix}: InfoItemBannersProps) => {
    if (infoItems.length === 0) return null;

    return (
        <HeaderArea>
            {infoItems.map(infoItem => {
                return (
                    <BannerLink linkUrl={`${pathPrefix}/info_items/${infoItem.id}`}>
                        {infoItem.title}
                    </BannerLink>)
            })}
        </HeaderArea>
    )
}

const HeaderArea = styled.div`
    box-sizing: border-box;
    font-family: sans-serif, 'Noto Sans JP';
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`

export default InfoItemBanners
