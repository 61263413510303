import React from 'react';
import styled from 'styled-components'
import {InfoItem} from "../../../types/infoItem";
import Title from "../../atoms/share/Title";

interface ShowProps {
    infoItem: InfoItem;
}

export const ShowInfoItem = ({infoItem}: ShowProps) => {
    return (
        <ShowArea>
            <Title value={infoItem.title}/>
            <div>
                {infoItem.detail_text}
            </div>
        </ShowArea>
    )
}

const ShowArea = styled.div``;

export default ShowInfoItem
