import React from 'react';
import styled from 'styled-components'
import NewInfoItem from '../../../organisms/management/info_items/NewInfoItem'

export const New = () => {
  return (
    <NewArea>
      <NewInfoItem />
    </NewArea>
  )
}

const NewArea = styled.div``;

export default New
