import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import {createInfoItem} from "../../../../api/info_item";
import InfoItemForm from "./InfoItemForm";

export const NewInfoItem = () => {
  return (
    <SignupArea>
      <Title value="お知らせ新規登録" />
      <InfoItemForm submitHandler={createInfoItem} />
    </SignupArea>
  )
}

const SignupArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`

export default NewInfoItem
