import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import InfoItems from '../../../organisms/management/info_items/InfoItems'

export const Index = () => {
  return (
    <IndexArea>
      <StaffArea>
        <TitleArea>
          <Title value='お知らせ一覧' />
        </TitleArea>
        <InfoItems />
      </StaffArea>
    </IndexArea>
  )
}

const IndexArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`
const StaffArea = styled.div``
const TitleArea = styled.div``

export default Index
