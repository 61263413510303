import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import TableBodyGrid from '../../../atoms/share/TableBodyGrid'
import LinkButton from '../../../atoms/share/LinkButton'
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import {fetchInfoItems, deleteInfoItem} from '../../../../api/info_item'
import LinkButtonDefault from '../../../atoms/share/button/LinkButtonDefault';
import formatDt from '../../../../utils/formatDateTime'

export const InfoItems = () => {
    const [infoItems, setInfoItems] = useState([]);

    useEffect(() => {
        fetchInfoItems()
            .then((res) => {
                setInfoItems(res.data)
            })
            .catch(() => {
                console.log('通信に失敗しました');
            });
    }, []);

    const heads = ['id', 'タイトル', '表示開始', '表示終了', '']

    const handleDelete = (id: number) => {
        const confirmed = window.confirm('お知らせを削除します。よろしいですか？');

        if (confirmed) {
            deleteInfoItem(id)
                .then((res) => {
                    setInfoItems(
                        infoItems.filter(item =>
                            item.id !== res.data.id
                        )
                    )
                })
                .catch(() => {
                    window.alert('通信に失敗しました')
                });
        }
    }

    const formatDateTime = (datetimeStr: string | null): string => {
        if (!datetimeStr) return ''

        const date = new Date(datetimeStr)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hour = date.getHours()
        const minute = date.getMinutes()

        return formatDt(year, month, day, hour, minute, 0);
    }

    return (
        <StaffListArea>
            <ButtonBox>
                <LinkButton text='新規登録' path='/management/info_items/new'/>
            </ButtonBox>
            <Table>
                <TableHead items={heads}/>
                <Tbody>
                    {infoItems.map((infoItem, index) => (
                        <Row key={index}>
                            <TableBody>{infoItem.id}</TableBody>
                            <TableBody>{infoItem.title}</TableBody>
                            <TableBody>{formatDateTime(infoItem.start_at)}</TableBody>
                            <TableBody>{formatDateTime(infoItem.end_at)}</TableBody>
                            <TableBodyGrid>
                                <TableButtonArea>
                                    <LinkButtonDefault path={`/management/info_items/${infoItem.id}`} text='詳細'/>
                                </TableButtonArea>
                                <TableButtonArea>
                                    <LinkButtonDefault path={`/management/info_items/${infoItem.id}/edit`} text='編集'/>
                                </TableButtonArea>
                                <TableButtonArea>
                                    <ButtonRed text='削除' onClick={() => handleDelete(infoItem.id)}/>
                                </TableButtonArea>
                            </TableBodyGrid>
                        </Row>
                    ))}
                </Tbody>
            </Table>
        </StaffListArea>
    )
}

const StaffListArea = styled.div`
    margin: 0 0 100px 0;
`
const Table = styled.table`
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
    table-layout: auto;
`
const Tbody = styled.tbody`
    display: contents;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 300px;
    border-bottom: 1px solid black;
`
const Row = styled.tr`
`
const ButtonBox = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: right;
    margin-bottom: 30px;
    font-size: 16px;
`
const TableButtonArea = styled.span`
    display: block;
    text-align: center;
`

export default InfoItems
