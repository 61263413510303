import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import {InfoItem} from "../../../../types/infoItem";
import {InfoItemRequestParams, updateInfoItem} from "../../../../api/info_item";
import InfoItemForm from "./InfoItemForm";

interface EditFormProps {
  infoItem: InfoItem;
}

export const EditForm = ({infoItem}: EditFormProps) => {
  const submitHandler = (params: InfoItemRequestParams) => {
    return updateInfoItem(infoItem.id, params)
  }

  return (
    <EditFormArea>
      <Title value="お知らせ編集" />
      <InfoItemForm submitHandler={submitHandler} infoItem={infoItem} />
    </EditFormArea>
  )
}

const EditFormArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`

export default EditForm
