import React from 'react'
import styled from 'styled-components'

export interface BannerLinkProps {
  children: string;
  linkUrl: any;
}

export const BannerLink: React.FC<BannerLinkProps> = ({ children, linkUrl }) => {
  return (
    <BannerLinkTag href={linkUrl}>
      {children}
    </BannerLinkTag>
  )
}

const BannerLinkTag = styled.a`
  display: block;
  width: 100%;
  background-color: #e0f2ff;
  color: #005080; 
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-bottom: 3px solid #b0d4f1;
  &:hover {
    background-color: #cbe7ff;
    color: #003366;
  }
`

export default BannerLink
